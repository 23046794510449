<template>
  <component :is="gameData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="gameData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching game data
      </h4>
      <div class="alert-body">
        No game found with this game id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-games-list'}"
        >
          Game List
        </b-link>
        for other games.
      </div>
    </b-alert>

    <b-tabs
      v-if="gameData"
      pills
    >

      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="GameIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Edit Game</span>
        </template>
        <game-edit-tab-account
          :game-data="gameData"
          class="mt-2 pt-75"
        />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import gameStoreModule from '../gameStoreModule'
import GameEditTabAccount from './GameEditTabAccount.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    GameEditTabAccount,
  },
  setup() {
    const gameData = ref(null)

    const GAME_APP_STORE_MODULE_NAME = 'games'

    // Register module
    if (!store.hasModule(GAME_APP_STORE_MODULE_NAME)) store.registerModule(GAME_APP_STORE_MODULE_NAME, gameStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(GAME_APP_STORE_MODULE_NAME)) store.unregisterModule(GAME_APP_STORE_MODULE_NAME)
    })

    store.dispatch('games/fetchGame', { id: router.currentRoute.params.id })
      .then(response => {
        gameData.value = response.data.data
      })
      .catch(error => {
        if (error.response.data.status === 404) {
          gameData.value = undefined
        }
      })

    return {
      gameData,
    }
  },
}
</script>

<style>

</style>
