import { ref, watch, computed } from '@vue/composition-api'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default function useGamesList() {
  // Use toast
  const toast = useToast()

  const refGameListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // {
    //   key: 'id',
    //   label: '#',
    //   sortable: true,
    //   thStyle: {
    //     display: 'none',
    //   },
    // },
    // {
    //   key: 'phone_number',
    //   label: 'Staff Phone',
    //   sortable: true,
    //   thStyle: {
    //     display: 'none',
    //   },
    // },
    {
      key: 'thumbnail',
      thStyle: {
        display: 'none',
      },
    },
    {
      key: 'is_offered',
      sortable: true,
      thStyle: {
        display: 'none',
      },
    },
    // {
    //   key: 'priority',
    //   sortable: true,
    //   thStyle: {
    //     display: 'none',
    //   },
    // },
    {
      key: 'tags',
      thStyle: {
        display: 'none',
      },
    },
    // {
    //   key: 'name',
    // },
    // {
    //   key: 'slug',
    // },
    // {
    //   key: 'num_bets',
    //   label: "All bets"
    // },
    // {
    //   key: 'provider',
    // },
    // {
    //   key: 'currency',
    // },
    
    {
      key: 'actions',
      thStyle: {
        display: 'none',
      },
    },
    
  ]
  const perPage = ref(10)
  const totalGames = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('-priority')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)
  const isBusy = ref(false)

  const dataMeta = computed(() => {
    const localItemsCount = refGameListTable.value ? refGameListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalGames.value,
    }
  })

  const refetchData = () => {
    refGameListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })

  const fetchGames = (ctx, callback) => {
    isBusy.value = true
    store
      .dispatch('games/fetchGames', {
        search: searchQuery.value,
        page_size: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value,
      })
      .then(response => {
        const { data, paginator } = response.data

        callback(data)
        totalGames.value = paginator.length
        isBusy.value = false
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching games' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveGameStatusVariantAndIcon = status => {
    if (status === true) return { variant: 'success', icon: 'CheckCircleIcon' }
    if (status === false) return { variant: 'danger', icon: 'XIcon' }
    return { variant: 'secondary', icon: 'XIcon' }
  }
  const resolveGameAvatarVariant = status => {
    if (status === true) return 'primary'
    if (status === false) return 'danger'
    return 'primary'
  }

  return {
    fetchGames,
    tableColumns,
    perPage,
    currentPage,
    totalGames,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refGameListTable,
    isBusy,

    statusFilter,

    resolveGameStatusVariantAndIcon,
    resolveGameAvatarVariant,
    refetchData,
  }
}
