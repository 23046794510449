import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchGames(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/games/', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchTags(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/games/tags/', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchGame(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/games/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addGame(ctx, gameData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/games/', gameData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editGame(ctx, { id, gameData }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/games/${id}/`, gameData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
