<template>
  <div>
    <!-- Game Info: Input Fields -->
    <b-form>
      <b-row>
        <!-- Field: Full Name -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Name"
            label-for="full-name"
          >
            <b-form-input
              id="full-name"
              v-model="gameData.name"
              disabled
            />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Priority"
            label-for="priority"
          >
            <b-form-input
              id="priority"
              v-model="gameData.priority"
              type="number"
            />
          </b-form-group>
        </b-col>
      </b-row>
        <!-- Field: Status -->
        <b-row>
        <b-col
          cols="8"
          md="4"
        >
          <b-form-group
            label="Offered for betting"
            label-for="game-status"
          >
          <b-form-checkbox switch size="lg" v-model="gameData.is_offered"></b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="8"
          md="8"
        >
        <div v-if="gameData.tags.length!==0" class="mb-2">
        <small>Currently assigned tags:</small>
        <li v-for="tag in gameData.tags.split(',')" :key="tag" class="list-inline-item">
              <b-form-tag
                class="ml-50"
                :title="tag"
                disabled
                variant="info"
              >{{ tag }}
            </b-form-tag>
            </li>
          </div>
        <b-form-group label="Select new Tags (Existing tags will be overidden)" label-for="tags-component-select">
      <!-- Prop `add-on-change` is needed to enable adding tags vie the `change` event -->
      <b-form-tags
        id="tags-component-select"
        v-model="newTags"
        size="lg"
        class="mb-2"
        add-on-change
        no-outer-focus
      >
        <template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
          <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
            <li v-for="tag in tags" :key="tag" class="list-inline-item">
              <b-form-tag
                @remove="removeTag(tag)"
                :title="tag"
                :disabled="disabled"
                variant="info"
              >{{ tag }}</b-form-tag>
            </li>
          </ul>
          <b-form-select
            v-bind="inputAttrs"
            v-on="inputHandlers"
            :disabled="disabled || availableOptions.length === 0"
            :options="availableOptions"
            :reduce="val => val.name"
          >
            <template #first>
              <!-- This is required to prevent bugs with Safari -->
              <option disabled value="">Choose a tag...</option>
            </template>
          </b-form-select>
        </template>
      </b-form-tags>
    </b-form-group>
        </b-col>
      </b-row>
    </b-form>
    <!-- Action Buttons -->
    <b-button
      variant="primary"
      type="button"
      @click="onSubmit"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      Save Changes
    </b-button>
    <b-button
      variant="outline-secondary"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      Reset
    </b-button>
  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BFormCheckbox, BFormTags, BFormSelect, BFormTag,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useToast } from 'vue-toastification/composition'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, toRefs } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useGamesList from '../games-list/useGamesList'
import store from '@/store'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
    BFormTags,
    BFormTag,
    BFormSelect,
    vSelect,
  },
  props: {
    gameData: {
      type: Object,
      required: true,
    },
  },
    computed: {
      availableOptions() {
        return this.options.filter(opt => this.newTags.indexOf(opt) === -1)
      }
    },
    mounted() {
    // payments
    this.$http.get('/games/tags/')
      .then(response => {
        console.log(response)
        this.options = response.data.data.map(obj => obj.name);
        
      })
  },
  setup(props) {
    const { resolveGameRoleVariant } = useGamesList()
    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)
    const approvalLimit = ref(0)
    const options = ref([])
    const newTags = ref([])
    const toast = useToast()
    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.gameData.avatar = base64
    })
    const gameDataRef = toRefs(props).gameData
    const onSubmit = () => {
    const gameEditData = {
      id: gameDataRef.value.id,
      tags: newTags.value.join(),
      is_offered: gameDataRef.value.is_offered,
      priority: gameDataRef.value.priority,
      thumbnail: gameDataRef.value.thumbnail
    }
    const formData = new FormData();
    formData.append("id", gameEditData.id)
    formData.append("tags", gameEditData.tags)
    formData.append("is_offered", gameEditData.is_offered)
    formData.append("priority", gameEditData.priority)
    // formData.append("thumbnail", JSON.stringify(gameEditData.thumbnail))
  
    console.log("Game data edited",gameEditData)
    store.dispatch('games/editGame', { id: gameEditData.id, gameData: formData })
      .then(() => {
        toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Changes Saved',
            icon: 'CoffeeIcon',
            variant: 'success',
            text: 'Changes have been changed Successfully',
          },
        })
      })
    }
    return {
      resolveGameRoleVariant,
      avatarText,
      onSubmit,
      gameDataRef,
      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      approvalLimit,
      options,
      newTags,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
